<template>
    <div>
        <el-form :inline="true">
            <el-form-item>
                <el-button type="primary" @click="addUpdateVehicletypeButton(null)" v-if="hasAuth('sys:vehicletype:save')">新增</el-button>
            </el-form-item>
        </el-form>
        <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                border
                stripe
              >
            <!-- <el-table-column
                    align="center"
                    fixed="left"
                    type="selection"
                    width="55">
            </el-table-column> -->
            <el-table-column
                    prop="vehicleType"
                    align="center"
                    label="车辆类型"
                    min-width="120">
            </el-table-column>
            <el-table-column
                    label="图片"
                    align="center"
                    width="250">
                <template slot-scope="scope">
                    <el-image style="width: 200px; height: 100px" :src="scope.row.vehiclePicture" :preview-src-list="scope.row.vehiclePicture"></el-image>
                </template>
            </el-table-column>
            <el-table-column
                    prop="lengthWidthHeight"
                    align="center"
                    label="长宽高"
                    width="120">
            </el-table-column>
            <el-table-column
                    prop="vehicleLoad"
                    align="center"
                    label="车辆载重"
                    width="120">
            </el-table-column>
            <el-table-column
                    prop="vehicleVolume"
                    align="center"
                    label="车辆体积"
                    width="120">
            </el-table-column>
            <el-table-column
                    prop="vehicleMoneyKm"
                    align="center"
                    label="车辆每公里费用"
                    width="120">
            </el-table-column>

            <el-table-column
                    prop="parkingFee"
                    align="center"
                    label="押车费"
                    width="120">
            </el-table-column>
            <el-table-column
                    prop="sort"
                    align="center"
                    label="排序"
                    width="120">
            </el-table-column>
            <el-table-column
                    prop="createdTime"
                    align="center"
                    width="200"
                    label="创建时间"
            >
            </el-table-column>
            <el-table-column
                    prop="icon"
                    align="center"
                    width="200px"
                    fixed="right"
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" plain @click="addUpdateVehicletypeButton(scope.row.vehicleTypeId)" v-if="hasAuth('sys:vehicletype:update')">编辑</el-button>
                    <template v-if="hasAuth('sys:vehicletype:delete')">
                        <el-popconfirm title="这是一段内容确定删除吗？" @confirm="delHandle(scope.row.vehicleTypeId)">
                            <el-button type="danger" plain slot="reference">删除</el-button>
                        </el-popconfirm>
                    </template>
                </template>
            </el-table-column>
        </el-table>
        <AddUpdateVehicletype ref="addUpdateVehicletype" v-if="addUpdateVehicletypevisible" @refreshDataList="getVehicleTypeList"></AddUpdateVehicletype>
    </div>
</template>

<script>
    import AddUpdateVehicletype from "./AddUpdateVehicletype";
    export default {
        name: "VehicletypeList",
        components:{
            AddUpdateVehicletype
        },
        data(){
            return{
                searchForm:{},
                delBtlStatu:true,
                tableData:[],
                addUpdateVehicletypevisible: false,
                multipleSelection:[]
            }
        },
        created() {
            this.getVehicleTypeList();
        },
        methods:{
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.delBtlStatu = val.length == 0
            },
            getVehicleTypeList() {
                this.$axios.post("/admin/vehicletype/getVehicleTypeList",{isDetele:1}).then(res => {
                    this.tableData = res.data
                })
            },
            delHandle(id) {
                this.$axios.post("/admin/vehicletype/deteleVehicleType", {vehicleTypeId:id}).then(res => {
                    this.$message({
                        showClose: true,
                        message: '恭喜你，操作成功',
                        type: 'success',
                        onClose:() => {
                            this.getUserList()
                        }
                    });
                })
            },
            addUpdateVehicletypeButton(id){
                this.addUpdateVehicletypevisible=true;
                this.$nextTick(()=>{
                    this.$refs.addUpdateVehicletype.init(id);
                })
            },
        }
    }
</script>

<style scoped>
    .el-button{
        padding: 0 10px;
        height: 35px;
        line-height:35px;
        font-size:15px;
        margin-right: 10px;
    }
</style>

